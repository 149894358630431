import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Email Writing Services",
  whatWeOffer: {
    leftPart: "Writing professional emails is an essential part of modern business. Getting the right words, the right tone, and hitting the sweet spot in terms of length is something that takes time and practice.",
    rightPart: "You can use them for product updates and newsletters, but also business correspondence and in-house collaboration. Whatever the case, our skilled content writing experts are here to help."
  },
  processText: "You can outsource all our email writing needs to our team. Get in touch with our management team, and we will provide you with a quote.",
  testimonials: testimonials
}

const meta = {
  title: "Email Writing Services",
  description: "Writing professional emails is an essential part of modern business. Getting the right words, the right tone, and hitting the sweet spot in terms of length is something that takes time and practice."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/email-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)